img{
    max-width: 100%;
}

section{
    padding-bottom: 20px;
}

.leaflet-container {
    height: 60vh;
}

#noPathSelected{
    margin:5px;
}

#pathInfo{
    padding:5px;
    /* background-image: url("https://bergslagsledencdnendpoint.azureedge.net/media/su5jrybu/ny-baenk_smaatjaernarna.jpg?width=1200&height=600&rnd=133543028426170000"); */
    /* color: white; */
}

.parentName{
    margin: 0;
    color:gray;
}

.pathName{
    margin: 0;
}

.icon{
    height: 24px;
    width: 24px;
    padding-right: 4px;
    display: inline-block;
}

.pathDetailSection{
    display:flex;
    /* float: left; */
    flex-flow: wrap;
    gap: 5px;
}

.pathDetail{
    display: flex;
    /* flex-direction: column; */
    color:gray;
    font-weight: bold;
    background-color: gainsboro;
    padding: 3px 8px;
}
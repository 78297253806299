#nav {
    height: 70px;
    width: 100%;
    padding: 0px 0 0px 0;
    color: #202020;
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  #nav-inner {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
  
  #menu {
    font-size: 25px;
    font-weight: 400;
    margin-top: 0px;
  }
  
  #menu li {
    float: left;
    display: block;
    padding: 15px 15px;
  }
  
  a:hover {
    text-decoration-line: underline;
    text-underline-offset: 8px;
  }
  
  a {
    color: #464040;
    text-decoration: none;
  }
  
  a.active {
    text-decoration-line: underline;
    text-underline-offset: 8px;
  }
  